<template>
  <div class="login">
    <div class="LoginFormWrapper">
      <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
              <b-form class="login" @submit.prevent="login('/user-data')">
                <h1 class>Zaloguj się</h1>
                <b-form-group class="mt-5" id="login" label="Login (numer karty)" label-for="login">
                  <b-input-group prepend="209">
                    <b-form-input v-model="formLogin.login"
                                  maxlength="13"
                                  placeholder="Wprowadż numer karty"
                                  @input="$v.formLogin.login.$touch()"
                                  :state="(!$v.formLogin.login.$error) && null"
                                  aria-describedby="login-feedback"></b-form-input>
                    <b-form-invalid-feedback id="login-feedback">
                      <div class="error" v-if="!$v.formLogin.login.required">Pole wymagane</div>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group id="elpassword" label="Hasło (data urodzenia)" label-for="login">
                  <b-input-group>
                    <b-form-input
                      v-on:keyup.enter="login('/user-data')"
                      :type="`date`"
                      v-model="formLogin.password"
                      placeholder="Podaj datę urodzenia w formacie RRRR-MM-DD"
                                  @input="$v.formLogin.password.$touch()"
                                  :state="(!$v.formLogin.password.$error) && null"
                                  aria-describedby="password-feedback"></b-form-input>
                    <b-form-invalid-feedback id="password-feedback">
                      <div class="error" v-if="!$v.formLogin.password.required">Pole wymagane</div>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
                <b-button variant="primary" class="float-right" type="button" @click="login('/user-data')"
                        :disabled="loading">Login
                </b-button>
              </b-form>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import NotifyService from '@/_services/notify';
import UserService from '@/_services/user.service';

const notify = new NotifyService();
const userService = new UserService();

export default {
  name: 'login',
  data() {
    return {
      loading: false,
      formLogin: {
        login: '',
        password: '',
      },

    };
  },
  methods: {
    login(redirect) {
      this.$v.formLogin.$touch();
      if (this.$v.formLogin.$anyError) {
        return;
      }
      this.loading = true;
      // jesli user wpisze karte z prefixem 209 mimo ze nie powinien i ma 13 znakow to ucinamy
      if (this.formLogin.login != null && this.formLogin.login && this.formLogin.login.match(/^209/g)) {
        this.formLogin.login = this.formLogin.login.substring(3, 20);
      }
      userService.loginSend(this.formLogin)
        .then((response) => {
          if (response.data.token.original) {
            notify.error('Błąd', `Błąd typu: ${response.data.token.original}`);
            return;
          }
          userService.clearSession();
          localStorage.setItem('token', response.data.token);
          if (redirect) {
            this.$router.push(redirect);
          }
          notify.success('OK', 'Logowanie przebiegło pomyślnie!');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  /**
     * walidacje z Vuelidate
     */
  validations: {
    formLogin: {
      login: {
        required,
      },
      password: {
        required,
      },
    },
  },
};
</script>
